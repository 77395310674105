<template>
  <div>
    <div class="task-nav">
      <div class="table-page-search-wrapper">
        <a-form layout="inline" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
          <a-row :gutter="24">
            <a-col :span="6">
              <a-form-item label="班级名称">
                <a-input
                  v-model="formClassName"
                  allow-clear
                  @pressEnter="initSearchData('search')"
                  placeholder="请输入"
                />
              </a-form-item>
            </a-col>

            <a-col :span="6">
              <a-form-item label="发单类型">
                <a-select placeholder="请选择" allowClear v-model="issuingType">
                  <a-select-option :value="item.value" v-for="(item, index) in issuingTypeArr" :key="index">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="状态">
                <a-select allowClear placeholder="请选择" v-model="statusSearch">
                  <a-select-option :value="item.value" v-for="(item, index) in statusTypeArr" :key="index">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6" v-if="userInfo.leader">
              <a-form-item label="组织(部门):">
                <a-tree-select
                  v-model="searchDeptId"
                  style="width: 100%"
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                  :tree-data="treeData"
                  placeholder="请选择"
                  tree-default-expand-all
                >
                </a-tree-select>
              </a-form-item>
            </a-col>
            <a-col :span="6" v-if="userInfo.leader">
              <a-form-item label="发单人:">
                <a-select
                  show-search
                  @search="searchClassAdmin"
                  v-model="ownerId"
                  :filter-option="filterOption"
                  option-filter-prop="children"
                  placeholder="请选择"
                  allow-clear
                >
                  <a-select-option :value="i.uuid" v-for="i of classAdminList" :key="i.uuid">
                    {{ i.fullName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24" style="float: right; text-align: right">
              <span class="table-page-search-submitButtons" :style="{ float: 'right', overflow: 'hidden' } || {}">
                <a-button type="primary" style="margin-right: 10px" @click="initSearchData('search')">查询</a-button>
                <a-button style="margin-right: 10px" @click="resetCondition">重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
    </div>
    <div class="table-wrap">
      <a-table
        :loading="loading"
        :columns="columns"
        :data-source="tableData"
        @change="handleTableChange"
        :pagination="initPagination"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
      >
        <template slot="classType" slot-scope="classType">
          <span>{{ initOptionsType(classType, classTypeArr) }}</span>
        </template>
        <template slot="teacherName" slot-scope="teacherName">
          <span>{{ teacherName ? teacherName : '-' }}</span>
        </template>
        <template slot="type" slot-scope="type">
          <span>{{ initOptionsType(type, issuingTypeArr) }}</span>
        </template>
        <template slot="status" slot-scope="status">
          <span :style="{ color: status === 'EXPIRE_CANCELLED' ? 'red' : '' }">{{
            initOptionsType(status, statusTypeArr)
          }}</span>
        </template>
        <template slot="operation" slot-scope="operation, record">
          <span class="operation" @click="lookDetail(record)">详情</span>
          <span class="operation" @click="cancelIssuingFun(record)" v-if="record.status === 'PENDING_ORDER'">取消</span>
          <span
            class="operation"
            @click="reissueOrderFun(record)"
            v-if="record.status === 'EXPIRE_CANCELLED' || record.status === 'CANCELLED'"
            >再次发单</span
          >
        </template>
      </a-table>
    </div>

    <a-drawer
      class="test-main"
      title="发单详情"
      :body-style="{ paddingBottom: '80px' }"
      placement="right"
      width="700"
      :visible="visible"
      @close="onClose"
    >
      <div class="task-info" v-if="formInfoData">
        <a-spin tip="Loading..." :spinning="spinning">
          <div class="task-form">
            <p class="form-name" style="margin-bottom: 12px">
              {{ initOptionsType(formInfoData.type, issuingTypeArr) }}
              <span class="pending-status" v-if="formInfoData.status === 'PENDING_ORDER'">待接单</span>
              <span class="success-status" v-if="formInfoData.status === 'ORDER_RECEIVED'">已接单</span>
              <span class="refuse-status" v-if="formInfoData.status === 'EXPIRE_CANCELLED'">超时已取消</span>
              <span class="refuse-status" v-if="formInfoData.status === 'CANCELLED'">已取消</span>
              <span class="refuse-status" v-if="formInfoData.status === 'REFUSE'">已拒绝</span>
            </p>
            <div class="form-teacher">
              <img :src="defaultImg" alt="" />
              <span>创建人：{{ formInfoData.owner }}</span>
              |
              <span>创建时间：{{ formInfoData.whenCreated }}</span>
            </div>
          </div>

          <div class="apply-detail">
            <InfoTitle title="申请详情" />
            <div class="apply-detailContent">
              <div class="apply-item">
                <span class="apply-label">所属科目：</span
                ><span class="apply-content">{{
                  (formInfoData.businessData && formInfoData.businessData.subject) === 'CHINESE' ? '中文' : '数学'
                }}</span>
              </div>
              <div class="apply-item">
                <span class="apply-label">班级名称：</span
                ><span class="apply-content" @click="hrefClass(formInfoData.formClassId)" style="cursor: pointer">{{
                  formInfoData.formClassName
                }}</span>
              </div>
              <div class="apply-item">
                <span class="apply-label">班级类型：</span
                ><span class="apply-content">{{
                  initOptionsType(formInfoData.businessData && formInfoData.businessData.classType, classTypeArr)
                }}</span>
              </div>
              <div
                class="apply-item"
                v-if="
                  formInfoData.type === 'URGENT_SUBSTITUTE_ORDER' ||
                  formInfoData.type === 'NORMAL_SUBSTITUTE_ORDER' ||
                  formInfoData.type === 'URGENT_SPECIFY_TEACHER_ORDER'
                "
              >
                <span class="apply-label">相关课节：</span
                ><span class="apply-content">
                  <div v-if="formInfoData.businessData && formInfoData.businessData.sectionTimes">
                    <div
                      class="class-item"
                      v-for="(subClassItem, index1) in formInfoData.businessData.sectionTimes"
                      :key="index1"
                    >
                      {{ subClassItem.courseSectionName }}
                      {{
                        `${$moment(subClassItem.startDateTime).format('YY/MM/DD')} ${$moment(
                          subClassItem.startDateTime,
                        ).format('dddd')} ${subClassItem.startDateTime.substring(
                          10,
                        )} - ${subClassItem.endDateTime.substring(11)}`
                      }}
                    </div>
                  </div>
                </span>
              </div>
              <div
                class="apply-item"
                v-if="formInfoData.type === 'URGENT_SCHEDULE_ORDER' || formInfoData.type === 'NORMAL_ORDER'"
              >
                <span class="apply-label">开始课节：</span
                ><span class="apply-content">{{
                  formInfoData.businessData && formInfoData.businessData.beginSectionName
                }}</span>
              </div>
              <div
                class="apply-item"
                v-if="formInfoData.type === 'URGENT_SCHEDULE_ORDER' || formInfoData.type === 'NORMAL_ORDER'"
              >
                <span class="apply-label">结束课节：</span
                ><span class="apply-content">{{
                  formInfoData.businessData && formInfoData.businessData.endSectionName
                }}</span>
              </div>

              <div class="apply-item">
                <span class="apply-label">课节数量：</span
                ><span class="apply-content">{{
                  formInfoData.businessData && formInfoData.businessData.sectionCount
                }}</span>
              </div>
              <div
                class="apply-item"
                v-if="formInfoData.type === 'URGENT_SCHEDULE_ORDER' || formInfoData.type === 'NORMAL_ORDER'"
              >
                <span class="apply-label">开始日期：</span
                ><span class="apply-content">{{
                  formInfoData.businessData && formInfoData.businessData.courseDate
                }}</span>
              </div>
              <div
                class="apply-item"
                v-if="formInfoData.type === 'URGENT_SCHEDULE_ORDER' || formInfoData.type === 'NORMAL_ORDER'"
              >
                <span class="apply-label">上课周期(北京)：</span
                ><span class="apply-content">
                  <span v-for="(item, index) in formInfoData.businessData.sectionTimes" :key="index">
                    {{
                      `${$moment(item.startDateTime).format('YYYY-MM-DD')} ${$moment(item.startDateTime).format(
                        'dddd',
                      )}(${item.startDateTime.substr(11, 5)}-${item.endDateTime.substr(11, 5)})`
                    }}<span v-if="formInfoData.businessData.sectionTimes.length !== index + 1"><br /></span>
                  </span>
                </span>
              </div>

              <div class="apply-item" v-if="formInfoData.type !== 'URGENT_SPECIFY_TEACHER_ORDER'">
                <span class="apply-label">授课老师语言：</span
                ><span class="apply-content">{{
                  formInfoData.businessData &&
                  formInfoData.businessData.language &&
                  formInfoData.businessData.language.join(',')
                }}</span>
              </div>
              <div class="apply-item" v-if="formInfoData.type !== 'URGENT_SPECIFY_TEACHER_ORDER'">
                <span class="apply-label">授课老师风格：</span
                ><span class="apply-content">{{
                  formInfoData.businessData &&
                  formInfoData.businessData.teachingStyle &&
                  formInfoData.businessData.teachingStyle.join(',')
                }}</span>
              </div>
              <div class="apply-item" v-if="formInfoData.type !== 'URGENT_SPECIFY_TEACHER_ORDER'">
                <span class="apply-label">期望老师性别：</span
                ><span class="apply-content">{{
                  formInfoData.businessData &&
                  formInfoData.businessData.gender &&
                  initOptionsType(formInfoData.businessData.gender, genderArr)
                }}</span>
              </div>
              <div class="apply-item" v-if="formInfoData.type === 'URGENT_SPECIFY_TEACHER_ORDER'">
                <span class="apply-label">指定授课老师：</span
                ><span class="apply-content">{{ formInfoData.teacherName }}</span>
              </div>
              <div class="apply-item">
                <span class="apply-label">抢单结束时间：</span
                ><span class="apply-content">{{
                  $moment(formInfoData.whenCreated)
                    .add(formInfoData.expireTime, 'seconds')
                    .format('YYYY-MM-DD HH:mm:ss')
                }}</span>
              </div>
              <div class="apply-item">
                <span class="apply-label">发单备注：</span
                ><span class="apply-content">{{
                  formInfoData.businessData &&
                  formInfoData.businessData.customData &&
                  formInfoData.businessData.customData.remark
                }}</span>
              </div>
            </div>
          </div>
          <div class="apply-detail">
            <InfoTitle title="抢单结果" />
            <div class="apply-detailContent">
              <div class="apply-item">
                <span class="apply-label">抢单人(授课老师)：</span
                ><span class="apply-content">{{ formInfoData.teacherName || '-' }}</span>
              </div>

              <div class="apply-item">
                <span class="apply-label">抢单结果：</span
                ><span class="apply-content">{{ initOptionsType(formInfoData.status, statusTypeArr) }}</span>
              </div>

              <div class="apply-item">
                <span class="apply-label">抢单时间：</span
                ><span class="apply-content">{{
                  (formInfoData.whenScheduling && $moment(formInfoData.whenScheduling).format('YYYY-MM-DD HH:mm:ss')) ||
                  '-'
                }}</span>
              </div>
            </div>
          </div>
          <div
            class="apply-detail"
            v-if="formInfoData.relationGroupOrders && formInfoData.relationGroupOrders.length > 0"
          >
            <InfoTitle title="关联单" />
            <div class="apply-detailContent">
              <div class="apply-item">
                <span class="apply-label">关联单：</span
                ><span class="apply-content">
                  <div style="margin-bottom: 10px" v-for="item in formInfoData.relationGroupOrders" :key="item.uuid">
                    【{{ initOptionsType(item.status, statusTypeArr) }}】（创建时间：{{ item.whenCreated }}）<span
                      class="lookDetail"
                      @click="lookDetailFun(item.uuid)"
                      >查看详情</span
                    >
                  </div>
                </span>
              </div>
            </div>
          </div>
        </a-spin>
      </div>
      <div class="taskFormBtn" v-if="formInfoData.status === 'PENDING_ORDER'">
        <a-button :style="{ marginRight: '8px' }" type="danger" @click="openCancel"> 取消 </a-button>
      </div>
      <div class="taskFormBtn" v-if="formInfoData.status === 'EXPIRE_CANCELLED' || formInfoData.status === 'CANCELLED'">
        <a-button :style="{ marginRight: '8px' }" type="primary" @click="reissueOrderFun(formInfoData)">
          再次发单
        </a-button>
      </div>
    </a-drawer>

    <a-modal
      title="取消"
      :visible="visibleCancel"
      @ok="cancelIssuing"
      @cancel="cancelTC"
      okText="确定取消"
      cancelText="关闭"
      :okButtonProps="{
        props: {
          type: 'danger',
          loading: cancelLoading,
        },
      }"
    >
      <div class="issuing-wrap">
        <div style="font-size: 16px; margin-bottom: 8px">您确定<span style="color: red">取消</span>该单吗？</div>
        <div>确定取消后，授课老师将不会再收到该单的通知</div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import storage from 'store';
import InfoTitle from '@/components/WkInfoTitle/InfoTitle';
import defaultImg from '@/assets/service_pic_head.png';
import { dayOfWeekOptions } from '@/utils/const';
import { getDdlLabel } from '@/utils/util';
import { issuingManagement, cancelIssuing, queryCourseSchedule, getTreeList, selClassAdmin } from '@/api/headTeacher';
import { getOrderAgainSheetDetail } from '@/api/schedule';

export default {
  name: 'shareLessons',
  components: { InfoTitle },
  watch: {
    $route() {
      this.initSearchData('search');
    },
  },
  data() {
    return {
      spinning: false,
      detailDataArr: [],

      classAdminList: [],
      treeData: [],
      searchDeptId: undefined,
      dayOfWeekOptions,
      cancelLoading: false,
      visibleCancel: false,
      genderArr: [
        { label: '男', value: 'M' },
        { label: '女', value: 'F' },
        { label: '不确定', value: 'U' },
      ],
      classTypeArr: [
        { label: '班课', value: 'GROUP' },
        { label: '私教课', value: 'PRIVATE' },
      ],
      formClassName: undefined,
      issuingType: undefined,
      statusSearch: this.$route.query.status,
      statusTypeArr: [
        { label: '待接单', value: 'PENDING_ORDER' },
        { label: '已接单', value: 'ORDER_RECEIVED' },
        { label: '已取消', value: 'CANCELLED' },
        { label: '已拒绝', value: 'REFUSE' },
        { label: '超时已取消', value: 'EXPIRE_CANCELLED' },
      ],
      issuingTypeArr: [
        { label: '常规排课单', value: 'NORMAL_ORDER' },
        { label: '紧急排课单', value: 'URGENT_SCHEDULE_ORDER' },
        { label: '紧急代课单', value: 'URGENT_SUBSTITUTE_ORDER' },
        { label: '常规代课单', value: 'NORMAL_SUBSTITUTE_ORDER' },
        { label: '紧急指定单', value: 'URGENT_SPECIFY_TEACHER_ORDER' },
      ],
      ownerId: undefined,
      userInfo: {},
      courseScheduleObj: {},
      tableSorter: {},
      remarkTC: '',
      examineTitle: '',
      visibleResult: false,
      actualData: {},
      visibleExamine: false,
      defaultImg,
      formInfoData: {},
      selType: 'PENDING',
      headmasterList: [],
      submitterId: undefined,
      transactionCategory: undefined,
      transactionCategoryArr: [
        { label: '冻结课程', value: '冻结课程' },
        { label: '延期开课', value: '延期开课' },
      ],
      approveStatus: undefined,
      approveStatusArr: [
        { label: '待审核', value: 'PENDING' },
        { label: '已通过', value: 'PASSED' },
        { label: '已拒绝', value: 'REJECTED' },
      ],
      visible: false,
      pageNum: 1,
      selTabData: [
        { label: '我提交', value: 'OWN' },
        { label: '待审批', value: 'PENDING' },
        { label: '已审批', value: 'PASSED' },
      ],

      columns: [
        {
          title: '序号',
          key: 'index',
          width: 60,
          fixed: 'left',
          dataIndex: 'num',
          customRender: (text, record, index) => `${(this.current - 1) * this.pageSize + (index + 1)}`,
        },
        {
          title: '班级名称',
          dataIndex: 'formClassName',
          key: 'formClassName',

          customCell: (record) => {
            return {
              style: {
                // 行背景色
                color: '#43D186',
                cursor: 'pointer',
              },
              on: {
                // 鼠标单击行
                click: () => {
                  const routeUrl = this.$router.resolve({
                    path: '/workoutSchedule/classDetail',
                    query: {
                      classId: record.formClassId,
                    },
                  });
                  window.open(routeUrl.href, '_blank');
                },
              },
            };
          },
        },
        {
          title: '班级类型',
          dataIndex: 'businessData.classType',
          key: 'classType',
          scopedSlots: { customRender: 'classType' },
        },
        {
          title: '发单类型',
          dataIndex: 'type',
          key: 'type',
          scopedSlots: { customRender: 'type' },
        },
        {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '抢单人(授课老师)',
          dataIndex: 'teacherName',
          key: 'teacherName',
          scopedSlots: { customRender: 'teacherName' },
        },
        {
          title: '创建人',
          dataIndex: 'owner',
          key: 'owner',
        },
        {
          title: '创建时间',
          dataIndex: 'whenCreated',
          key: 'whenCreated',
          sorter: true,
        },

        {
          title: '操作',
          dataIndex: 'operation',
          with: 120,
          fixed: 'right',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      tableData: [],
      current: 0,
      pageSize: 10,
      initPagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },

      loading: false,
    };
  },
  methods: {
    getDdlLabel,
    lookDetailFun(uuid) {
      this.spinning = true;
      let params = {
        scheduleOrderId: uuid,
      };
      getOrderAgainSheetDetail(params).then((res) => {
        this.spinning = false;
        this.formInfoData = res.data.scheduleOrderRespDTO;
      });
    },

    reissueOrderFun(record) {
      //判断当前班级存在待接单

      //不存在，重新发单，常规排课单、紧急排课单跳到排课页面
      if (record.type === 'NORMAL_ORDER' || record.type === 'URGENT_SCHEDULE_ORDER') {
        this.$router.push({
          path: '/workoutSchedule/courseSchedule',
          query: {
            scheduleOrderId: record.uuid,
            formType: 'bill',
            classId: record?.businessData?.formClassId,
          },
        });
      } else if (record.type === 'URGENT_SPECIFY_TEACHER_ORDER') {
        //指定单

        this.$router.push({
          path: '/workoutSchedule/issuingCourseTeacherSheet',
          query: {
            scheduleOrderId: record.uuid,
            formType: 'bill',
          },
        });
      } else if (record.type === 'URGENT_SUBSTITUTE_ORDER' || record.type === 'NORMAL_SUBSTITUTE_ORDER') {
        //发紧急、常规代课单

        this.$router.push({
          path: '/courseSheet',
          query: {
            scheduleOrderId: record.uuid,
            type: record.type, // 未匹配到老师，下常规代课单
            formType: 'bill',
          },
        });
      }
    },
    hrefClass(formClassId) {
      const routeUrl = this.$router.resolve({
        path: '/workoutSchedule/classDetail',
        query: {
          classId: formClassId,
        },
      });
      window.open(routeUrl.href, '_blank');
    },
    searchClassAdmin(name) {
      const params = {
        name,
      };
      selClassAdmin(params).then((res) => {
        this.classAdminList = res.data.content;
      });
    },
    getTreeList() {
      getTreeList().then((res) => {
        this.treeData = res.data;
      });
    },
    cancelIssuingFun(record) {
      this.formInfoData = record;
      this.visibleCancel = true;
    },
    openCancel() {
      this.visibleCancel = true;
    },
    // 取消派单
    cancelTC() {
      this.visibleCancel = false;
    },
    cancelIssuing() {
      const params = {
        orderId: this.formInfoData.uuid,
      };
      this.cancelLoading = true;
      cancelIssuing(params)
        .then((res) => {
          this.cancelLoading = false;
          this.$message.success('取消成功');
          this.visible = false;
          this.visibleCancel = false;
          this.initSearchData('search');
        })
        .catch((error) => {
          this.cancelLoading = false;
        });
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.indexOf(input) >= 0;
    },

    // 查询课节
    queryCourseSchedule(uuid) {
      const params = {
        uuid,
      };

      queryCourseSchedule(params).then((res) => {
        this.courseScheduleObj = res.data.content;
      });
    },

    initOptionsType(value, typeArr) {
      let name = '';
      typeArr?.forEach?.((item) => {
        if (item.value === value) {
          name = item.label;
        }
      });
      return name;
    },

    onClose() {
      this.visible = false;
    },

    lookDetail(record) {
      this.formInfoData = record;
      this.visible = true;
    },
    onSearchReport() {},

    handleTableChange(data, filters, sorter) {
      this.tableSorter = sorter;

      this.pageNum = data.current;
      this.initPagination.current = data.current;

      this.initSearchData();
    },

    resetCondition() {
      this.issuingType = undefined;
      this.statusSearch = undefined;
      this.formClassName = undefined;
      this.searchDeptId = undefined;
      this.ownerId = undefined;
      this.pageNum = 1;
      this.pageSize = 10;
      this.initSearchData();
    },
    initSearchData(act) {
      if (act === 'search') {
        this.pageNum = 1;
      }

      const params = {
        formClassName: this.formClassName,
        formClassId: this.$route?.query?.formClassId,
        type: this.issuingType,
        status: this.statusSearch,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        isClassAdmin: true,
      };
      if (this.userInfo.leader) {
        params.isClassAdmin = false;
        params.departmentId = this.searchDeptId;
        params.ownerId = this.ownerId;
      }
      let sort = '';
      if (this.tableSorter.order === 'descend') {
        sort = 'desc';
      } else if (this.tableSorter.order === 'ascend') {
        sort = 'asc';
      }
      if (this.tableSorter.order) {
        params.orderBy = `${this.tableSorter.columnKey} ${sort}`;
      }

      this.loading = true;
      issuingManagement(params).then((res) => {
        this.tableData = res.data.content;
        this.current = res.data.currentPage;
        this.pageSize = res.data.pageSize;
        this.initPagination.total = res.data.totalCount;
        this.initPagination.pageSize = res.data.pageSize;
        this.loading = false;
      });
    },
  },
  created() {
    this.userInfo = storage.get('userInfo');
    if (this.userInfo.roleName === 'CLASS_ADMIN') {
      this.selType = 'OWN';
    }
    if (this.userInfo.leader) {
      this.getTreeList();
    }
  },
  mounted() {
    this.initSearchData();
  },
};
</script>

<style lang="less" scoped>
/deep/ .table-page-search-wrapper .ant-form-inline .ant-form-item > .ant-form-item-label {
  width: 120px;
  text-align: right;
}
.table-page-search-wrapper {
  background-color: #fff;
  margin-bottom: 8px;
  padding: 20px 20px 0;
}
.ant-advanced-search-form {
  /deep/ .ant-col-16 {
    width: 80%;
  }
  /deep/ .ant-form-item-label {
    width: 68px;
  }
}
.operation {
  color: #3372fe;
  cursor: pointer;
  margin-right: 5px;
}
// .task-nav {
//   background-color: #fff;
//   margin-bottom: 8px;
//   padding: 20px 0 0;
//   display: flex;
//   justify-content: space-between;
// }
.ant-advanced-search-form {
  /deep/ .ant-col-16 {
    width: 70%;
  }
}
.table-wrap {
  padding: 20px;
  background: #fff;
  min-height: calc(100vh - 200px);
}
.sel-navwrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.task-info {
  .task-type {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
    margin-bottom: 20px;
    span {
      padding: 2px 10px;
      background-color: #f7f7f7;
      border-radius: 6px;
      margin-right: 10px;
    }
  }
  .task-form {
    border-bottom: 1px solid #eee;
    .form-name {
      font-size: 20px;
      font-weight: 600;
      color: #333333;
      line-height: 28px;
      span {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        padding: 2px 10px;
        border-radius: 6px;
        margin: 0 10px;
      }
    }
    .pending-status {
      color: #ffbf00;
      background-color: rgba(255, 191, 0, 0.09);
    }
    .success-status {
      color: #00cf64;
      background-color: rgba(0, 207, 100, 0.09);
    }
    .refuse-status {
      color: #ff5353;
      background-color: rgba(255, 83, 83, 0.09);
    }
    .form-teacher {
      font-size: 16px;
      font-weight: 400;
      color: #999;
      line-height: 22px;
      margin-bottom: 20px;
      .dueDate {
        color: #ff5454;
      }
      span {
        color: #333;
      }
      img {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
    .form-des {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
    }
    .form-imgs {
      img {
        width: 80px;
        height: 80px;
        border-radius: 4px;
        margin-right: 10px;
      }
      margin-bottom: 20px;
    }
  }
  .courseInfo {
    padding: 20px 0;
    .course-info {
      padding: 20px 16px;
      background: linear-gradient(180deg, #ecf7ff 0%, #f7fcff 100%);
      border-radius: 20px;
      .course-name {
        font-size: 16px;
        font-weight: 600;
        color: #009cff;
        line-height: 22px;
        margin-bottom: 10px;
        &:hover {
          text-decoration: underline;
        }
      }
      .course-type {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 18px;
        margin-top: 10px;
      }
    }
  }
}

.apply-item {
  margin-bottom: 10px;
  display: flex;
}
.apply-label {
  color: rgba(31, 45, 61, 0.4);
  min-width: 43px;
}
.apply-content {
  color: #1f2d3d;
  flex: 1;
}
.student-item {
  display: flex;
  justify-content: flex-start;
  margin-right: 20px;
}
.student-item p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.student-item img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 7px;
}
.student-item .student-name {
  font-size: 16px;
  font-weight: 600;

  line-height: 22px;
}
.student-wrap {
  display: flex;
  margin-bottom: 22px;
}
.cursor {
  cursor: pointer;
  color: #43d186 !important;
}
.line-wrap {
  margin-top: 20px;

  border-bottom: 1px solid #eee;
}
.apply-detail {
  margin-top: 20px;
}
.examine-item {
  margin-bottom: 14px;
  display: flex;
}
.examine-label {
  width: 80px;
}
.examine-content {
  flex: 1;
}
.trans-wrap {
  background: rgba(0, 0, 0, 0.02);
  height: 280px;
  display: flex;
}
.trans-itemWrap {
  flex: 1;
  padding: 10px 20px;
}
.trans-item {
  margin-top: 5px;
}
.taskFormBtn {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 700px;
  border-top: 1px solid #e9e9e9;
  background-color: #fff;
  padding: 10px 16px;
  background: '#fff';
  text-align: right;
  z-index: 1;
}
.lookDetail {
  color: #04cb94;
  cursor: pointer;
}
</style>
